<template>
  <div class="regin">
    <div class="title">我的银行信息</div>
    <div class="line">
      <span class="lable">开户行</span>
      <input type="text" name="" v-model="khname" />
    </div>
    <!--    <div class="line">
      <span class="lable">性别</span>
      <van-radio-group class="sexGroup" v-model="sex" ref="sex" icon-size="16px" checked-color="#AB856F"
        direction="horizontal">
        <van-radio name="1">男</van-radio>
        <van-radio name="2">女</van-radio>
      </van-radio-group>
    </div> -->
    <div class="line">
      <span class="lable">账号</span>
      <input type="number" name="" v-model="banknum" />
    </div>
    <div class="line">
      <span class="lable">户名</span>
      <input type="text" name="" v-model="bankname" />
    </div>
    <div class="bottomBtn" @click="save()">保存</div>

  </div>
</template>
<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader,
    Toast
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from 'js-cookie';
  import {
    getQueryVariable
  } from "@/utils/request";
  import area from '@/utils/4-area.json'
  export default {
    name: "regin",
    props: {},
    data() {
      return {
        banknum: '',
        khname: '',
        bankname: '',
        quxianJson: [],
        name: '',
        sex: '',
        card: '',
        tjr: '',
        tel: '',
        address: '', //' 所属国家 出生地
        workplace: '', // 工作地
        email: '',

      };
    },
    components: {
      [RadioGroup.name]: RadioGroup,
      [DatetimePicker.name]: DatetimePicker,
      [Radio.name]: Radio,
      [Popup.name]: Popup,
      [Cascader.name]: Cascader,
    },
    mounted() {
      this.quxianJson = this.parseArea();
      this.userCode = Cookies.get("kh");
      //读出配置信息
      this.getMyInfo();
      document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
        // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
        WeixinJSBridge.call('hideOptionMenu');
      });
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      parseArea() {
        //console.log(area);
        //console.log(area instanceof Array);
        let areaJs = JSON.parse(JSON.stringify(area));
        console.log(areaJs instanceof Array);
        let result = areaJs.map(item => {
          return {
            text: item.pro_name,
            value: item.pro_code,
            children: item.pro_cities.map(e => {
              return {
                text: e.city_name,
                value: e.city_code,
                children: e.city_areas.map(val => {
                  return {
                    text: val.area_name,
                    value: val.area_code,
                    children: val.area_streets.map(t => {
                      return {
                        text: t.street_name,
                        value: t.street_code
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return result;
      },
      chooseBirthComplete() {
        this.chooseBirthPlace = false;
      },
      chooseBirthChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.country = areaName;
      },
      onBirthFinish({
        selectedOptions
      }) {
        this.chooseBirthPlace = false;
      },
      chooseWorkComplete() {
        this.chooseWorkPlace = false;
      },
      chooseWorkChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.workplace = areaName;
      },
      onWorkFinish({
        selectedOptions
      }) {
        this.chooseWorkPlace = false;
      },
      async save() {
        //保存本次修改的个人信息
        var sex1 = ""; //1男2女
        if (this.sex == "2") {
          sex1 = '女'; //1男2女
        } else {
          sex1 = '男';
        }
        //保存自己的银行卡信息
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/mallsubmit.ashx";
        var param = {
          action: "updateUserInfo",
          user: Cookies.get("kh"),
          banknum: that.banknum,
          khname: that.khname,
          bankname: that.bankname,
          name: that.name, //这值本界面不修改,但是要读出并且传值
          sex: that.sex, //这值本界面不修改,但是要读出并且传值
          card: that.card, //这值本界面不修改,但是要读出并且传值
          tjr: that.tjr, //这值本界面不修改,但是要读出并且传值
          tel: that.tel, //这值本界面不修改,但是要读出并且传值
          address: that.address, //' 所属国家 出生地  //这值本界面不修改,但是要读出并且传值
          workplace: that.workplace, //' 工作地  //这值本界面不修改,但是要读出并且传值
          email: that.email, //这值本界面不修改,但是要读出并且传值
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            this.$router.push("home");
            //延迟时间：3秒
          }, 500)
        } else {
          Toast(result.msg);
        }
      },
      async getMyInfo() {
        //获取自己的个人信息 加载个人信息
        let that = this;
        let uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.banknum = result.banknum; //银行卡号
          that.khname = result.khname; //银行名字
          that.bankname = result.bankname; //账户持有人名称
          that.name = result.name; //这值本界面不修改,但是要读出并且传值
          that.sex = result.sex; //这值本界面不修改,但是要读出并且传值
          that.card = result.card; //这值本界面不修改,但是要读出并且传值
          that.tjr = result.tjr; //这值本界面不修改,但是要读出并且传值
          that.tel = result.tel; //这值本界面不修改,但是要读出并且传值
          that.address = result.address; //' 所属国家 出生地  //这值本界面不修改,但是要读出并且传值
          that.workplace = result.workplace; //' 工作地  //这值本界面不修改,但是要读出并且传值
          that.email = result.email; //这值本界面不修改,但是要读出并且传值

        } else {
          Toast(result.msg);
        }

      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        text-indent: 12px;
        color: #464646;
        text-indent: 70px;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;

      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #ea2e3a;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }
</style>
